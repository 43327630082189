<template>
  <sword-modal class="reset blocked-devices-modal"
               :header="$t('warning.camera_and_micro_title')"
               @close="closeModal">
    <template slot="body">
      <p>{{ $t("warning.camera_and_micro_messages") }}</p>
    </template>

    <template slot="footer">
      <sword-button-wuk
        :text="$t('copy_1061')"
        @click.native="closeModal" />
    </template>
  </sword-modal>
</template>

<script>
import SwordModal from '@ui-kit/components/modals/SwordModal.vue';
import SwordButtonWuk from '@ui-kit/components/buttons/SwordButton.vue';

export default {
  name: 'BlockedDevicesModal',

  components: {
    SwordModal,
    SwordButtonWuk,
  },

  emits: [ 'close' ],

  methods: {
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.blocked-devices-modal {
  &::v-deep {
    .modal-top-bar-icon {
      height: 16px;
      width: 16px;
      margin: map-get($sh-spacers-wuk, 1);
    }

    .sword-modal-content {
      padding-top: map-get($sh-spacers-wuk, 8) !important;
    }

    .sword-modal-body {
      margin-bottom: map-get($sh-spacers-wuk, 0) !important;
    }
  }
}
</style>
