<template>
  <div class="container">
    <h5 class="info-header">Video call confirmation</h5>
    <p class="info-text">Please fill in and confirm the data requested below</p>
    <sword-dropdown-wuk v-model="callType"
                        label="Call Type"
                        :options="videoCallTypeOptions" />
    <sword-checkbox-wuk v-model="correctMemberAcknowledgementValue"
                        :opt-value="correctMemberAcknowledgementValue">
      I confirm that I am talking to the correct person
    </sword-checkbox-wuk>
    <sword-button-wuk class="submit-button"
                      text="Confirm"
                      type="primary"
                      size="large"
                      :disabled="callType === undefined || !correctMemberAcknowledgementValue"
                      @click.native="submitMetadata" />
  </div>
</template>

<script>
import SwordButtonWuk from '@ui-kit/components/buttons/SwordButton.vue';
import SwordCheckboxWuk from '@ui-kit/components/inputs/checkboxes/SwordCheckbox.vue';
import SwordDropdownWuk from '@ui-kit/components/inputs/dropdowns/SwordDropdown.vue';
import { mapMutations } from 'vuex';

export default {
  name: 'VideoCallMetadataForm',
  components: {
    SwordButtonWuk,
    SwordCheckboxWuk,
    SwordDropdownWuk,
  },
  data() {
    return {
      callType: undefined,
      correctMemberAcknowledgementValue: false,
      videoCallTypeOptions: [
        {
          optLabel: 'Onboarding',
          optValue: 'ONBOARDING',
        },
        {
          optLabel: 'Learn and Setup',
          optValue: 'LEARN_AND_SETUP',
        },
        {
          optLabel: 'Reassessment',
          optValue: 'REASSESSMENT',
        },
        {
          optLabel: 'Check In',
          optValue: 'CHECK_IN',
        },
        {
          optLabel: 'Other',
          optValue: 'OTHER',
        },
      ],
    };
  },
  methods: {
    ...mapMutations({
      setCallType: 'call/group/setCallType',
    }),
    submitMetadata() {
      this.setCallType(this.callType);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 400px;
  padding: 0 1rem 1rem 1rem;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  background: $white;
}

.info-header {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.info-text {
  @extend %t3;
  margin-bottom: 2rem;
}

.submit-button {
  display: block;
  margin: 2rem auto 0 auto;
}
</style>
