<template>
  <div class="reset call-controls" :class="sizeClass">
    <div class="control-slot --main-controls">
      <sword-button-wuk class="control-button"
                        :class="[themeClass, {'--off': !hasAudio}]"
                        :icon="hasAudio ? 'microphone' : 'microphone-off'"
                        round
                        theme="transparent"
                        size="medium"
                        @click.native="toggleAudio" />
      <sword-button-wuk class="control-button"
                        :class="[themeClass, {'--off': !hasVideo}]"
                        :icon="hasVideo ? 'camera' : 'camera-off'"
                        round
                        theme="transparent"
                        size="medium"
                        @click.native="toggleVideo" />
      <sword-button-wuk v-if="inOngoing"
                        class="control-button --off"
                        round
                        icon="telephone"
                        theme="transparent"
                        size="medium"
                        @click.native="endCall" />
    </div>

    <div class="control-slot --settings">
      <sword-button-wuk class="control-button"
                        :class="themeClass"
                        round
                        icon="settings"
                        theme="transparent"
                        size="medium"
                        @click.native="openSettings" />
    </div>

    <div v-if="size==='large'" class="control-slot --participants">
      <p class="participant-info">
        {{ joinedParticipantNames ?
          $t('copy_4687', { member_name: joinedParticipantNames }) :
          $t('copy_4707') }}
      </p>
    </div>
  </div>
</template>

<script>
import SwordButtonWuk from '@ui-kit/components/buttons/SwordButton.vue';

export default {
  name: 'CallControls',

  components: {
    SwordButtonWuk,
  },

  emits: [ 'set-audio', 'set-video', 'open-settings' ],
  props: {
    theme: {
      type: String,
      default: 'transparent',
      validator: value => [ 'transparent', 'secondary' ].includes(value),
    },
    size: {
      type: String,
      default: 'large',
      validator: value => [ 'large', 'small' ].includes(value),
    },
    call: {
      type: Object,
    },
    participantNames: {
      type: Array,
      default: () => ([]),
    },
    hasVideo: {
      type: Boolean,
      required: true,
    },
    hasAudio: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    themeClass() {
      return `--${this.theme}`;
    },
    sizeClass() {
      return `--${this.size}`;
    },
    inOngoing() {
      return this.call ? [ 'entered', 'connected' ].includes(this.call.state) : false;
    },
    joinedParticipantNames() {
      if (!this.participantNames?.length) {
        return '';
      }

      return this.participantNames.length === 1 ? this.participantNames : this.participantNames.join(', ');
    },
  },
  methods: {
    endCall() {
      this.$emit('end');
    },
    toggleAudio() {
      if (this.hasAudio) {
        if (this.call) this.call.muteMicrophone();
        this.$emit('set-audio', false);
      } else {
        if (this.call) this.call.unmuteMicrophone();
        this.$emit('set-audio', true);
      }
    },
    toggleVideo() {
      if (this.hasVideo) {
        if (this.call) this.call.stopVideo();
        this.$emit('set-video', false);
      } else {
        if (this.call) this.call.startVideo();
        this.$emit('set-video', true);
      }
    },
    openSettings() {
      this.$emit('open-settings');
    },
  },
};
</script>

<style lang="scss" scoped>
$min-gap-elements: calc(#{map-get($sh-spacers-wuk, 3)} / 2);

@mixin call-controls-grid {
  display: grid;
  grid-template-areas:
    ". controls settings"
    "participants participants participants";
  grid-template-columns: 1fr 4fr 1fr;
  align-items: center;

  @media screen and (min-width: $tablet-size) {
    grid-template-areas: "participants controls settings";
    grid-template-columns: 1fr 1.2fr 1fr;
  }
}

.call-controls:not(.--small) {
  @include call-controls-grid;
}

.call-controls.--small {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: $min-gap-elements;

  @media screen and (min-width: $tablet-size) {
    @include call-controls-grid;
  }
}

.control-slot {
  &.--main-controls {
    grid-area: controls;
    text-align: center;

    .control-button {
      margin: map-get($sh-spacers-wuk, 0) $min-gap-elements;
    }
  }

  &.--settings {
    grid-area: settings;
    text-align: right;

    .control-button {
      margin: map-get($sh-spacers-wuk, 0);
    }
  }

  &.--participants {
    grid-area: participants;
    margin-top: map-get($sh-spacers-wuk, 3);
    margin-right: map-get($sh-spacers-wuk, 0);
    text-align: center;

    @media screen and (min-width: $tablet-size) {
      margin-top: map-get($sh-spacers-wuk, 0);
      margin-right: $min-gap-elements;
      text-align: left;
    }
  }
}

.control-button {
  &.--secondary,
  &.--transparent:hover:not(:disabled) {
    background: $white;
    border-color: $white;
    color: getColor($greys, 1);
  }

  &.--secondary:hover:not(:disabled) {
    background-color: getColor($greys, 6);
    border-color: getColor($greys, 1);
  }

  &.--off {
    background: $hard-red;
    border-color: $hard-red;
    color: white;
  }
}

.participant-info {
  @extend %t2;
  @extend %-t_medium;
  color: $white;
}
</style>
