class SendBirdGroupCallEvent {
  constructor(room) {
    this.room = room;
    this._createRoomHandler();

    this.onConnected = null;
    this.onError = null;
    this.onReconnected = null;
    this.onRemoteParticipantStreamStarted = null;
    this.onRemoteParticipantEntered = null;
    this.onRemoteParticipantExited = null;
    this.onRemoteAudioSettingsChanged = null;
    this.onRemoteVideosettingsChanged = null;
  }

  _createRoomHandler() {
    this.room.on('connected', room => {
      if (this.onConnected) {
        this.onConnected(room);
      }
    });

    this.room.on('error', (error, participant) => {
      if (this.onError) {
        this.onError(error, participant);
      }
    });

    this.room.on('reconnected', room => {
      if (this.onReconnected) {
        this.onReconnected(room);
      }
    });

    this.room.on('remoteParticipantStreamStarted', participant => {
      if (this.onRemoteParticipantStreamStarted) {
        this.onRemoteParticipantStreamStarted(participant);
      }
    });

    this.room.on('remoteParticipantEntered', participant => {
      if (this.onRemoteParticipantEntered) {
        this.onRemoteParticipantEntered(participant);
      }
    });

    this.room.on('remoteParticipantExited', participant => {
      if (this.onRemoteParticipantExited) {
        this.onRemoteParticipantExited(participant);
      }
    });

    this.room.on('remoteAudioSettingsChanged', participant => {
      if (this.onRemoteAudioSettingsChanged) {
        this.onRemoteAudioSettingsChanged(participant);
      }
    });

    this.room.on('remoteVideosettingsChanged', participant => {
      if (this.onRemoteAudioSettingsChanged) {
        this.onRemoteAudioSettingsChanged(participant);
      }
    });
  }
}

export default SendBirdGroupCallEvent;
