<template>
  <div class="group-call-room">
    <loading-screen v-if="loading('call')" :message="$t('copy_4685')" />
    <waiting-room v-else-if="callState === CALL_STATES.WAITING" @join="joinCall" />
    <ongoing-call v-else-if="callState === CALL_STATES.ONGOING" :room="room" @end="endCall" />

    <call-ended-feedback v-show="callState === CALL_STATES.ENDED" @rejoin="rejoinCall" />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';

import groupCallEvents from '@/mixins/group-call-events';

import LoadingScreen from '@/components/loaders/LoadingScreen.vue';
import WaitingRoom from '@/components/video-call/WaitingRoom.vue';
import OngoingCall from '@/components/video-call/OngoingCall.vue';
import CallEndedFeedback from '@/components/video-call/CallEndedFeedback.vue';

const CALL_STATES = {
  ONGOING: 'ONGOING',
  ENDED: 'ENDED',
  WAITING: 'WAITING',
};

export default {
  name: 'GroupCallRoom',

  components: {
    LoadingScreen,
    WaitingRoom,
    OngoingCall,
    CallEndedFeedback,
  },
  mixins: [ groupCallEvents ],

  data() {
    return {
      callState: CALL_STATES.WAITING,
      CALL_STATES,
    };
  },

  computed: {
    ...mapGetters({
      loading: 'status/loading',
      room: 'call/group/getActiveRoom',
      roomIdFromStore: 'call/group/getRoomId',
      isAudioEnabled: 'call/media/isAudioEnabled',
      isVideoEnabled: 'call/media/isVideoEnabled',
    }),
  },

  beforeDestroy() {
    if (this.room) {
      this.room.exit();
    }
  },

  methods: {
    ...mapMutations({
      setLoading: 'status/setLoading',
      setReady: 'status/setReady',
      setLocalMediaAccess: 'call/media/setLocalMediaAccess',
    }),
    ...mapActions({
      updateActiveRoom: 'call/group/updateActiveRoom',
      updateTokenFromRoomId: 'call/group/updateTokenFromRoomId',
      turnCamera: 'call/media/turnCamera',
      disposeMediaAccess: 'call/media/disposeMediaAccess',
    }),
    async getRoomId() {
      const localRoomId = this.$route.params?.roomId || this.roomIdFromStore;
      if (localRoomId) {
        return localRoomId;
      }

      const token = this.$route.params?.roomToken;
      if (!token) {
        return null;
      }

      const roomId = await this.updateTokenFromRoomId(token);
      return roomId;
    },
    async enterRoom(room) {
      await room.enter({
        audioEnabled: this.isAudioEnabled,
        videoEnabled: this.isVideoEnabled,
      });
      this.handleLocalMedia(room.localParticipant);
    },
    handleLocalMedia(call) {
      const localMediaAccess = call.localStream._mediaAccess;
      localMediaAccess.fromCall = true;
      this.setLocalMediaAccess(localMediaAccess);

      localMediaAccess.on('streamChanged', () => {
        console.log('[call-media] call video stream changed');
        if (!this.isVideoEnabled) {
          this.turnCamera({ on: false });
        }
      });
    },
    async joinCall() {
      this.setLoading('call');

      try {
        const roomId = await this.getRoomId();
        console.log('[group-call] joining room', roomId);
        const room = await this.updateActiveRoom(roomId);
        this.createRoomEvent(room);

        if (!room.isOnCall) {
          await this.enterRoom(room);
        }

        this.callState = this.CALL_STATES.ONGOING;
        this.setReady('call');
      } catch (error) {
        console.log('[group-call] error trying to get room', error);
        this.$router.push({ name: 'Error' });
      }
    },
    async endCall() {
      await this.disposeMediaAccess();

      console.log('[group-call] exiting the room');
      this.room.exit();

      this.callState = this.CALL_STATES.ENDED;
    },
    async rejoinCall() {
      this.$router.go(this.$router.currentRoute);
    },
  },
};
</script>

<style lang="scss" scoped>
.group-call-room {
  height: 100%;
  width: 100%;
}
</style>
