<template>
  <div class="main-app-view">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'Main',
};
</script>

<style lang="scss" scoped>
.main-app-view {
  height: 100%;
}
</style>
