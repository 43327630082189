<template>
  <div ref="videoContainer"
       class="reset video-container"
       :class="{
         '--small': size === 'small',
         '--radius': borderRadius
       }">
    <div ref="videoContentRatio" class="video-content-ratio">
      <video-call-metadata-form v-if="showMetadataForm"
                                class="video-call-metadata-form" />
      <video v-show="hasVideo"
             ref="video"
             autoplay
             playsinline
             class="video-player"
             :controls="false"
             :muted="isVideoLocal"
             :class="{'--local': (call && call.isLocalParticipant) || hasLocalStream}" />

      <div v-show="!hasVideo" class="video-placeholder">
        <sword-icon name="user" class="placeholder-icon" />
        <p class="placeholder-message">
          {{ $t('copy_4793') }}
        </p>
      </div>

      <div v-show="!hasAudio" class="video-warning">
        <div class="round-icon">
          <sword-icon name="microphone-off" class="muted-icon" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isSafari } from '@/scripts/utils';

import SwordIcon from '@ui-kit/components/icons/SwordIcon.vue';
import VideoCallMetadataForm from '@/components/video-call/VideoCallMetadataForm.vue';

export default {
  name: 'VideoCall',

  components: {
    SwordIcon,
    VideoCallMetadataForm,
  },
  props: {
    dimensionToBeCalculated: {
      type: String,
      default: 'both',
      validator: value => [ 'none', 'height', 'width', 'both' ].includes(value),
    },
    size: {
      type: String,
      default: 'large',
      validator: value => [ 'large', 'small' ].includes(value),
    },
    call: {
      type: Object,
    },
    hasLocalStream: {
      type: Boolean,
      default: false,
    },
    localStream: {
      type: MediaStream,
    },
    hasVideo: {
      type: Boolean,
      default: true,
    },
    hasAudio: {
      type: Boolean,
      default: true,
    },
    borderRadius: {
      type: Boolean,
      default: false,
    },
    showMetadataForm: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    showControls() {
      return (!this.isVideoLocal) && isSafari();
    },
    isVideoLocal() {
      return this.hasLocalStream || this.call?.isLocalParticipant;
    },
  },

  watch: {
    size() {
      this.updateVideoDimensions();
    },
    localStream() {
      if (this.hasLocalStream && this.$refs.video) {
        this.$refs.video.srcObject = this.localStream;
      }
    },
  },

  async mounted() {
    if (!this.hasLocalStream && this.call) {
      this.call.setMediaView(this.$refs.video);
    }

    this.$nextTick(() => {
      this.updateVideoDimensions();
      window.addEventListener('resize', this.updateVideoDimensions);
    });
  },

  beforeDestroy() {
    window.addEventListener('resize', this.updateVideoDimensions);
  },

  methods: {
    updateVideoDimensions() {
      const videoContainerRef = this.$refs.videoContainer;
      const videoContentRef = this.$refs.videoContentRatio;

      if (videoContainerRef && videoContentRef) {
        const ASPECT_RATIO = 16 / 9;
        switch (this.dimensionToBeCalculated) {
          case 'height':
            this.updateVideoHeight(ASPECT_RATIO);
            break;

          case 'both':
            this.updateVideoHeightAndWidth(ASPECT_RATIO);
            break;

          default:
            videoContentRef.style.setProperty('height', '100%');
            videoContentRef.style.setProperty('width', '100%');
            break;
        }
      }
    },
    updateVideoHeight(aspectRatio) {
      const videoContainerRef = this.$refs.videoContainer;
      const videoContentRef = this.$refs.videoContentRatio;
      const containerWidth = videoContainerRef.clientWidth;

      const newContentHeight = (1 / aspectRatio) * containerWidth;
      videoContentRef.style.setProperty('height', `${newContentHeight}px`);
    },
    updateVideoHeightAndWidth(aspectRatio) {
      const videoContainerRef = this.$refs.videoContainer;
      const videoContentRef = this.$refs.videoContentRatio;
      const containerHeight = videoContainerRef.clientHeight;
      const containerWidth = videoContainerRef.clientWidth;

      const idealVideoHeight = (1 / aspectRatio) * containerWidth;

      if (idealVideoHeight <= containerHeight) {
        const newContentHeight = (1 / aspectRatio) * containerWidth;
        videoContentRef.style.setProperty('height', `${newContentHeight}px`);
        videoContentRef.style.setProperty('width', `${containerWidth}px`);
      } else {
        const newContentWidth = aspectRatio * containerHeight;
        videoContentRef.style.setProperty('height', `${containerHeight}px`);
        videoContentRef.style.setProperty('width', `${newContentWidth}px`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$local-video-shadow: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.5));

.video-container {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  width: 100%;

  &.--radius {
    .video-content-ratio,
    .video-player,
    .video-placeholder {
      border-radius: 6px;
    }
  }
}

.video-content-ratio {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  width: 100%;
  background-color: $black;
}

.video-player {
  aspect-ratio: 16 / 9;
  height: calc(100% + 2px);
  width: calc(100% + 2px);

  &.--local {
    transform: scaleX(-1);
  }
}

.video-placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: calc(100% + 2px);
  width: calc(100% + 2px);
  background-color: getColor($greys, 7);

 .placeholder-icon {
    color: getColor($greys, 1);
    height: 48px;
    width: 48px;

    @media screen and (min-width: $tablet-size) {
      height: 64px;
      width: 64px;
    }
  }
}

.placeholder-message {
  @extend %t2;
  @extend %-t_medium;
  display: none;
  margin-top: map-get($sh-spacers-wuk, 2);
  color: getColor($greys, 1);

  @media screen and (min-width: $tablet-size) {
    display: block;
  }
}

.video-warning {
  position: absolute;
  top: map-get($sh-spacers-wuk, 1);
  left: map-get($sh-spacers-wuk, 1);

  @media screen and (min-width: $tablet-size) {
    top: map-get($sh-spacers-wuk, 2);
    left: map-get($sh-spacers-wuk, 2);
  }
  @media screen and (min-width: $desktop-size) {
    top: map-get($sh-spacers-wuk, 3);
    left: map-get($sh-spacers-wuk, 3);
  }
}

.round-icon {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 40px;
  width: 40px;
  background-color: getColor($greys, 5);
  border-radius: 50%;

  .muted-icon {
    height: 20px;
    width: 20px;
    color: $white;
  }
}

.video-container.--small {
  filter: $local-video-shadow;

  .placeholder-icon {
    height: 48px;
    width: 48px;
  }

  .placeholder-message {
    display: none;
  }

  .video-warning {
    top: map-get($sh-spacers-wuk, 1);
    left: map-get($sh-spacers-wuk, 1);
  }

  .round-icon {
    height: 32px;
    width: 32px;
  }

  .muted-icon {
    height: 16px;
    width: 16px;
  }
}

.video-call-metadata-form {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;
}
</style>
