<template>
  <div class="call-view">
    <loading-screen v-if="loading('room')" />
    <router-view v-else />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';

import LoadingScreen from '@/components/loaders/LoadingScreen.vue';

export default {
  name: 'GroupCall',

  components: {
    LoadingScreen,
  },

  computed: {
    ...mapGetters({
      loading: 'status/loading',
    }),
  },

  async mounted() {
    this.setLoading('room');
    await this.initSendbirdCall();

    try {
      await this.connectToSendbirdCall();
      this.setReady('room');
    } catch (error) {
      console.log('[sendbird-call] error trying to authenticate with SendBird Call', error);
      this.$router.push({ name: 'Error' });
    }
  },

  onDestroy() {
    this.disconnectFromSendbirdCall();
  },

  methods: {
    ...mapMutations({
      setLoading: 'status/setLoading',
      setReady: 'status/setReady',
    }),
    ...mapActions({
      initSendbirdCall: 'call/initSendbirdCall',
      connectToSendbirdCall: 'call/connectToSendbirdCall',
      disconnectFromSendbirdCall: 'call/disconnectFromSendbirdCall',
    }),
  },

};
</script>

<style lang="scss" scoped>
.call-view {
  height: 100%;
  width: 100%;
}
</style>
