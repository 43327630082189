import { FEEDBACK_SCREEN_ACTIONS } from '@/configs/constants';

export default {
  NotFound: {
    text: 'This page doesn\'t exist',
    image: 'user-waiting',
  },
  callEnded: {
    title: 'You left the video call.',
    text: ' If this was a mistake, you can rejoin.',
    image: 'call-ended',
    action: {
      type: FEEDBACK_SCREEN_ACTIONS.EMIT_EVENT,
      buttonText: 'Join again',
      event: 'rejoin',
    },
  },
};
