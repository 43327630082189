import SendBirdGroupCallEvent from '@/scripts/sendbird-integration/SendBirdGroupCallEvent';

export default {
  methods: {
    createRoomEvent(roomInstance) {
      const callEvent = new SendBirdGroupCallEvent(roomInstance);
      console.log('[events] setting up call events');

      callEvent.onConnected = room => {
        console.log('[group-call-events] Connected to room:', room);
      };

      callEvent.onError = (error, participant) => {
        console.log('[group-call-events] Error in room:', error, participant);
      };

      callEvent.onReconnected = room => {
        console.log('[group-call-events] Room reconnected:', room);
      };

      callEvent.onRemoteParticipantEntered = participant => {
        console.log('[group-call-events] Participant entered room:', participant?.participantId);
      };

      callEvent.onRemoteParticipantExited = participant => {
        console.log('[group-call-events] Participant exited room:', participant?.participantId);
      };
    },
  },
};
