<template>
  <div class="feedback-view">
    <div class="message-container">
      <img class="feedback-img -mb_2"
           :src="getFeedbackImg()"
           alt="user waiting authentification">

      <p class="-my_0 -mt_5">{{ message.title && message.title[0] }}</p>
      <p class="-my_0 -mt_1">{{ message.text && message.text[0] }}</p>
      <sword-button-wuk v-if="actionButton"
                        class="-mt_3"
                        :text="actionButton.text"
                        type="primary"
                        size="small"
                        @click.native="actionButton.action"/>
    </div>

    <div v-if="showFooter" class="bottom-annotations-container -pb_2">
      <a class="swordhealth-website-link t4 fc-blue-4"
         rel="noopener noreferrer"
         :href="redirectUrl"
         target="_blank">
        SWORDHEALTH.COM
      </a>
    </div>
  </div>
</template>

<script>
import configs from '@/configs';
import { FEEDBACK_SCREEN_ACTIONS } from '@/configs/constants';
import feedbackCopies from '@/scripts/feedback-copies';

import SwordButtonWuk from '@ui-kit/components/buttons/SwordButton.vue';

export default {
  components: { SwordButtonWuk },
  props: {
    feedbackName: {
      type: String,
      default: '',
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    this.scrollToTop();
  },
  data() {
    return {
      feedbacks: feedbackCopies,
      genericFeedback: { title: null, text: [ 'Something went wrong' ], image: 'user-waiting' },
    };
  },
  computed: {
    context() {
      return this.feedbackName || this.$route.name;
    },
    message() {
      return this.getStateFeedback(this.context);
    },
    redirectUrl() {
      return `${configs.get('CLINICAL_PORTAL_LOGIN_URL')}?redirect=${window.location.href}`;
    },
    actionButton() {
      if (!this.message?.action) {
        return null;
      }

      const { action } = this.message;
      switch (action.type) {
        case FEEDBACK_SCREEN_ACTIONS.EMIT_EVENT:
          return {
            text: action.buttonText,
            action: () => {
              this.$emit(action.event);
            },
          };
        default:
          return null;
      }
    },
  },
  methods: {
    getFeedbackImg() {
      if (!this.message?.image) {
        return '';
      }

      // eslint-disable-next-line import/no-dynamic-require, global-require
      return require(`@/assets/feedbacks/${this.message.image}.svg`);
    },
    getStateFeedback(context) {
      if (!context || context === 'Error') {
        return this.genericFeedback;
      }

      const copyDef = this.feedbacks[context];
      return {
        ...copyDef,
        title: copyDef ? this.makeProp(copyDef.title) : [],
        text: copyDef ? this.makeProp(copyDef.text) : [],
      };
    },
    makeProp(src) {
      if (!src) {
        return [];
      }
      return typeof src === 'string' ? [ src ] : src;
    },
    scrollToTop() {
      this.$nextTick(() => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      });
    },
  },
};
</script>


<style lang="scss" scoped>
  .feedback-img {
    width: 15rem;
    max-width: 80%;
    margin: 0 auto;
  }

  .feedback-view {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .message-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
    flex-grow: 1;
    max-width: 90%;
    text-align: center;
    align-items: center;
  }

  .bottom-annotations-container {
    text-align: center;
  }

  .swordhealth-website-link {
    display: block;
    text-decoration: none;
  }
</style>
