<template>
  <sword-layout>
    <div class="feedback">
      <feedback-screen
        feedback-name="callEnded"
        :show-footer="false"
        @rejoin="rejoin" />
    </div>
  </sword-layout>
</template>

<script>
import SwordLayout from '@/components/layout/SwordLayout.vue';
import FeedbackScreen from '@/views/FeedbackScreen.vue';

export default {
  name: 'CallEndedFeedback',

  components: {
    SwordLayout,
    FeedbackScreen,
  },

  methods: {
    rejoin() {
      this.$emit('rejoin');
    },
  },
};
</script>

<style lang="scss" scoped>
.feedback {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}
</style>
