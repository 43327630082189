<template>
  <sword-layout>
    <div class="reset waiting-room">
      <div class="video-area">
        <video-call dimension-to-be-calculated="height"
                    :border-radius="true"
                    :has-local-stream="true"
                    :local-stream="localStream"
                    :has-video="isVideoEnabled" />

        <call-controls v-if="areDevicesLoaded"
                       class="video-controls"
                       theme="secondary"
                       size="small"
                       :class="{ '--background': isVideoEnabled }"
                       :has-audio="isAudioEnabled"
                       :has-video="isVideoEnabled"
                       @set-audio="toggleLocalAudio"
                       @set-video="() => togglePreviewVideo()"
                       @open-settings="openSettingsModal" />
      </div>

      <div class="cta-area right-column">
        <h2 class="cta-title">
          {{ $t('copy_4683') }}
        </h2>
        <p class="cta-subtitle">
          {{ $t('copy_4684') }}
        </p>

        <sword-button-wuk v-if="areDevicesLoaded"
                          theme="primary"
                          :text="$t('copy_4706')"
                            size="large"
                          @click.native="joinCall" />
        <div v-else class="cta-loading">
          <pulse-loader-v2 />
        </div>
      </div>
    </div>

    <settings-modal v-if="isSettingsModalOpen" :is-preview-video="true" @close="closeSettingsModal" />
    <blocked-devices-modal v-if="isBlockedDevicesModalOpen" @close="closeBlockedDevicesModal" />
  </sword-layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import SwordButtonWuk from '@ui-kit/components/buttons/SwordButton.vue';
import PulseLoaderV2 from '@ui-kit/components/loaders/PulseLoaderV2.vue';
import SwordLayout from '@/components/layout/SwordLayout.vue';
import VideoCall from '@/components/video-call/VideoCall.vue';
import CallControls from '@/components/video-call/CallControls.vue';
import SettingsModal from '@/components/video-call/SettingsModal.vue';
import BlockedDevicesModal from '@/components/video-call/BlockedDevicesModal.vue';

export default {
  name: 'WaitingRoom',

  components: {
    SwordLayout,
    VideoCall,
    CallControls,
    SettingsModal,
    BlockedDevicesModal,
    SwordButtonWuk,
    PulseLoaderV2,
  },

  emits: [ 'join' ],

  data() {
    return {
      localStream: null,
      isBlockedDevicesModalOpen: false,
      isSettingsModalOpen: false,
    };
  },

  computed: {
    ...mapGetters({
      hasMediaPermission: 'call/media/hasMediaPermission',
      isAudioEnabled: 'call/media/isAudioEnabled',
      isVideoEnabled: 'call/media/isVideoEnabled',
      areDevicesLoaded: 'call/media/areDevicesLoaded',
      videoStream: 'call/media/getVideoStream',
    }),
  },

  watch: {
    videoStream(newStream) {
      this.localStream = newStream;
    },
    hasMediaPermission(hasPermission) {
      if (!hasPermission) {
        this.openBlockedDevicesModal();
      }
    },
  },

  async mounted() {
    this.toggleLocalAudio(this.isAudioEnabled);
    this.togglePreviewVideo(this.isVideoEnabled);
  },

  methods: {
    ...mapActions({
      updateMediaPermission: 'call/media/updateMediaPermission',
      toggleLocalAudio: 'call/media/toggleLocalAudio',
      togglePreviewVideo: 'call/media/togglePreviewVideo',
      disposeMediaAccess: 'call/media/disposeMediaAccess',
    }),
    async joinCall() {
      await this.updateMediaPermission();
      if (this.hasMediaPermission) {
        await this.disposeMediaAccess();
        this.$emit('join');
      } else {
        this.openBlockedDevicesModal();
      }
    },
    openSettingsModal() {
      this.isSettingsModalOpen = true;
    },
    closeSettingsModal() {
      this.isSettingsModalOpen = false;
    },
    openBlockedDevicesModal() {
      this.isBlockedDevicesModalOpen = true;
    },
    closeBlockedDevicesModal() {
      this.isBlockedDevicesModalOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
$max-video-width: 576px;
$video-gradient: linear-gradient(180deg, rgba(29, 56, 97, 0.0001) 0%, #103061 95.82%);

.waiting-room {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: map-get($sh-spacers-wuk, 4);
  padding: map-get($sh-spacers-wuk, 0) map-get($sh-spacers-wuk, 3);

  @media screen and (min-width: $tablet-size) {
    gap: map-get($sh-spacers-wuk, 7);
  }
  @media screen and (min-width: $desktop-size) {
    flex-direction: row;
    gap: 0;
    .right-column {
      margin-left: min(7%, 120px);
    }
  }
}

.video-area {
  position: relative;
  width: 100%;
  max-width: $max-video-width;
}

.video-controls {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 999;
  padding: map-get($sh-spacers-wuk, 2);

  &.--background {
    background: $video-gradient;
    background-blend-mode: multiply;
    border-radius: 0px 0px 6px 6px;
  }

  @media screen and (min-width: $tablet-size) {
    padding: map-get($sh-spacers-wuk, 3);
  }
}

.cta-area {
  color: getColor($greys, 1);
  text-align: center;

  @media screen and (min-width: $desktop-size) {
    max-width: 284px;
  }
}

.cta-title {
  @extend %-t_bold;
  font-size: $font-base-20;
}

.cta-subtitle {
  margin: map-get($sh-spacers-wuk, 3) map-get($sh-spacers-wuk, 0) map-get($sh-spacers-wuk, 5);
  line-height: 22px;
}

.cta-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: map-get($sh-spacers-wuk, 5);
}
</style>
