<template>
  <div class="ongoing-call">
    <div class="video-area">
      <!-- Local participant -->
      <video-call class="video-container"
                  :class="{ '--shrink': hasRemoteParticipants }"
                  :size="hasRemoteParticipants? 'small' : 'large'"
                  :call="localParticipant"
                  :has-audio="isAudioEnabled"
                  :has-video="isVideoEnabled" />

      <!-- Remote participant -->
      <video-call v-for="participant in remoteParticipants"
                  :key="participant.participantId"
                  :call="participant"
                  :show-metadata-form="showMetadataForm"
                  :has-audio="participant.isAudioEnabled"
                  :has-video="participant.isVideoEnabled" />
    </div>

    <call-controls class="video-controls"
                   theme="secondary"
                   :call="localParticipant"
                   :participant-names="remoteParticipantsNames"
                   :has-audio="isAudioEnabled"
                   :has-video="isVideoEnabled"
                   @set-audio="toggleLocalAudio"
                   @set-video="toggleLocalVideo"
                   @open-settings="openSettingsModal"
                   @end="endCall" />

    <settings-modal v-if="isSettingsModalOpen" @close="closeSettingsModal" />
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';

import store from '@/store';

import { URLS } from '@/configs/constants';

import VideoCall from '@/components/video-call/VideoCall.vue';
import CallControls from '@/components/video-call/CallControls.vue';
import SettingsModal from '@/components/video-call/SettingsModal.vue';

import { uuid4 } from '@/scripts/utils';
import { ONE_SEC_IN_MS } from '@/scripts/dpt-activity/constants';
import { computeDiffInSeconds } from '@/scripts/dpt-activity/utils';

export default {
  name: 'OngoingCall',

  components: {
    VideoCall,
    CallControls,
    SettingsModal,
  },

  props: {
    room: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isSettingsModalOpen: false,
      activityUuid: uuid4(),
      hasCreatedActivity: false,
      callTypeActivity: undefined,
      dptActivityInterval: undefined,
      startTime: undefined,
    };
  },

  computed: {
    ...mapGetters({
      callType: 'call/group/getCallType',
      currUser: 'user/getUserDetails',
      memberOnCallAccountUuid: 'call/group/getMemberOnCallAccountUuid',
      isAudioEnabled: 'call/media/isAudioEnabled',
      isVideoEnabled: 'call/media/isVideoEnabled',
    }),
    participants() {
      const participants = this.room?.participants ?? [];
      console.log('[sendbird-group-call] number of participants in the call: ', participants.length);
      return participants;
    },
    hasRemoteParticipants() {
      return Boolean(this.remoteParticipants.length);
    },
    remoteParticipants() {
      return this.participants.filter(p => !p.isLocalParticipant);
    },
    remoteParticipantsNames() {
      return this.remoteParticipants.map(p => p.user?.nickname);
    },
    localParticipant() {
      return this.room.localParticipant;
    },
    isLocalParticipantTherapist() {
      return Boolean(this.room.localParticipant?.user?.userId.includes('pt'));
    },
    hasProviderEnabled() {
      return this.currUser?.hasProviderEnabled;
    },
    showMetadataForm() {
      const hasMemberOnCall = this.memberOnCallAccountUuid !== undefined;
      const hasCallType = this.callType !== undefined;

      return this.hasProviderEnabled && this.isLocalParticipantTherapist && hasMemberOnCall && !hasCallType;
    },
  },

  mounted() {
    window.addEventListener('beforeunload', this.handleCloseTab);

    if (this.hasProviderEnabled) {
      this.updateMemberOnCallAccountUuid(this.remoteParticipants);
    }
  },

  beforeDestroy() {
    window.removeEventListener('beforeunload', this.handleCloseTab);
    if (this.hasProviderEnabled) {
      this.endDPTActivityTracking();
    }
  },

  methods: {
    ...mapActions({
      updateMemberOnCallAccountUuid: 'call/group/updateMemberOnCallAccountUuid',
      toggleLocalAudio: 'call/media/toggleLocalAudio',
      toggleLocalVideo: 'call/media/toggleLocalVideo',
    }),
    handleCloseTab(event) {
      this.$emit('end');

      // The settings below are required for the user to be prompted to confirm the page unloading...
      event.preventDefault(); // Firefox
      // eslint-disable-next-line no-param-reassign
      event.returnValue = ''; // Gecko, Trident, Chrome 34+
      return ''; // Gecko, WebKit, Chrome <34
    },
    endCall() {
      this.$emit('end');
    },
    openSettingsModal() {
      this.isSettingsModalOpen = true;
    },
    closeSettingsModal() {
      this.isSettingsModalOpen = false;
    },
    startDPTActivityTracking() {
      if (this.dptActivityInterval === undefined) {
        console.log(`[dpt-activity] video call activity ${this.activityUuid} has begun`);
        this.startTime = new Date();

        this.dptActivityInterval = setInterval(this.sendDPTActivity, 5 * ONE_SEC_IN_MS);
      }
    },
    async endDPTActivityTracking() {
      console.log(`[dpt-activity] video call activity ${this.activityUuid} has ended`);
      clearInterval(this.dptActivityInterval);
      await this.sendDPTActivity();

      this.dptActivityInterval = undefined;
      this.startTime = undefined;
      this.hasCreatedActivity = false;

      // If the member hops back on the call, we will treat it as a separate call
      this.activityUuid = uuid4();
    },
    async sendDPTActivity() {
      const { callType, memberOnCallAccountUuid } = this;

      if (callType && memberOnCallAccountUuid) {
        const endTime = new Date();
        const activeDuration = computeDiffInSeconds(this.startTime, endTime);

        const token = store.getters['user/auth/getAuth'].accessToken;

        if (this.hasCreatedActivity) {
          await Vue.$http.put(
            `${URLS.THERAPIST_API}v1/dpt-activities/${this.activityUuid}/heartbeat`,
            {
              end_time: endTime.toISOString(),
              active_duration: activeDuration,
            },
            { headers: { Authorization: token } },
          );

          console.log(`[dpt-activity] video call activity ${this.activityUuid} has completed heartbeat`);
        } else {
          const activity = {
            uuid: this.activityUuid,
            account_uuid: memberOnCallAccountUuid,
            activity: `ACTIVITY_${callType}_VIDEO_CALL`,
            start_time: this.startTime.toISOString(),
            end_time: endTime.toISOString(),
            active_duration: activeDuration,
          };

          await Vue.$http.post(
            `${URLS.THERAPIST_API}v1/dpt-activities`,
            activity,
            { headers: { Authorization: token } },
          );

          console.log(`[dpt-activity] video call activity ${this.activityUuid} has been initialized`);

          this.hasCreatedActivity = true;
        }
      } else if (!memberOnCallAccountUuid) {
        // In the edge case that the original API call didn't get the member account UUID
        // since we should already know the member's account UUID when sendDPTActivity is called
        this.updateMemberOnCallAccountUuid(this.participants);
      }
    },
  },
  watch: {
    remoteParticipants(newMemberParticipants) {
      if (this.hasProviderEnabled) {
        this.updateMemberOnCallAccountUuid(newMemberParticipants);
      }
    },
    memberOnCallAccountUuid(newUuid, oldUuid) {
      if (this.hasProviderEnabled) {
        if (newUuid && !oldUuid) {
          // Member has joined the call
          this.startDPTActivityTracking();
        } else if (!newUuid && oldUuid) {
          // Member has left the call
          this.endDPTActivityTracking();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$small-video-height: 105px;
$max-small-video-width: 160px;

.ongoing-call {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  height: 100%;
  padding: map-get($sh-spacers-wuk, 4) map-get($sh-spacers-wuk, 2);
  background-color: getColor($greys, 1);

  @media screen and (min-width: $tablet-size) {
    padding: map-get($sh-spacers-wuk, 5) map-get($sh-spacers-wuk, 5);
  }
  @media screen and (min-width: $desktop-size) {
    padding: map-get($sh-spacers-wuk, 5) map-get($sh-spacers-wuk, 6);
  }
}

.video-area {
  position: relative;
  flex-grow: 1;
  width: 100%;
  overflow: hidden;
}

.video-container {
  &.--shrink {
    position: absolute;
    bottom: map-get($sh-spacers-wuk, 2);
    right: map-get($sh-spacers-wuk, 2);
    z-index: 999;

    height: $small-video-height;
    width: 100%;
    max-width: $max-small-video-width;
  }
}

.video-controls {
  width: 100%;
  margin-top: map-get($sh-spacers-wuk, 5);
}
</style>
