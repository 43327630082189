<template>
  <sword-modal class="settings-modal"
               scrollable
               :header="$t('copy_1905')"
               @close="closeModal">
    <template slot="body">
      <div class="field-container">
        <div class="field-wrap">
          <sword-dropdown-wuk v-model="selectedDevices.videoInputId"
                              name="videoInput"
                              class="field"
                              :label="$t('camera')"
                              :placeholder="!deviceOptions.videoInput? $t('no_camera') : undefined"
                              :options="deviceOptions.videoInput"
                              :disabled="!deviceOptions.videoInput" />
        </div>

        <div class="field-wrap">
          <sword-icon-wuk name="camera" class="icon" />
        </div>
      </div>

      <div class="field-container">
        <div class="field-wrap">
          <sword-dropdown-wuk v-model="selectedDevices.audioInputId"
                              name="audioInput"
                              class="field"
                              :label="$t('microphone')"
                              :placeholder="!deviceOptions.audioInput? $t('no_microphone') : undefined"
                              :options="deviceOptions.audioInput"
                              :disabled="!deviceOptions.audioInput" />
        </div>

        <div class="field-wrap">
          <sword-icon-wuk name="microphone" class="icon" />
        </div>
      </div>

      <div class="field-container">
        <div class="field-wrap">
          <sword-dropdown-wuk v-model="selectedDevices.audioOutputId"
                              name="audioOutput"
                              class="field"
                              :label="$t('speaker')"
                              :placeholder="!deviceOptions.audioOutput? $t('no_speakers') : undefined"
                              :options="deviceOptions.audioOutput"
                              :disabled="!deviceOptions.audioOutput" />
        </div>

        <div class="field-wrap">
          <sword-icon-wuk name="sound" class="icon" />
        </div>
      </div>
    </template>

    <template slot="footer">
      <sword-button-wuk
        :text="$t('COMMON.SAVE')"
        @click.native="saveMediaDevices" />
    </template>
  </sword-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import SwordModal from '@ui-kit/components/modals/SwordModal.vue';
import SwordDropdownWuk from '@ui-kit/components/inputs/dropdowns/SwordDropdown.vue';
import SwordIconWuk from '@ui-kit/components/icons/SwordIcon.vue';
import SwordButtonWuk from '@ui-kit/components/buttons/SwordButton.vue';

// Enumeration and selection of output devices (speakers) are not implemented yet in Firefox
// So we set a default value for when there is no audio output listed
const DEFAULT_AUDIO_OUTPUT_VALUE = 'defaultAudioOutput';

export default {
  name: 'SettingsModal',

  components: {
    SwordModal,
    SwordDropdownWuk,
    SwordIconWuk,
    SwordButtonWuk,
  },

  emits: [ 'close' ],
  props: {
    isPreviewVideo: {
      type: Boolean,
      required: false,
    },
  },

  data() {
    return {
      deviceOptions: {
        videoInput: [],
        audioInput: [],
        audioOutput: [],
      },
      selectedDevices: {
        videoInputId: null,
        audioInputId: null,
        audioOutputId: null,
      },
      deviceChangeListener: null,
    };
  },

  computed: {
    ...mapGetters({
      isVideoEnabled: 'call/media/isVideoEnabled',
      availableMediaDevices: 'call/media/getAvailableMediaDevices',
    }),
  },

  async mounted() {
    await this.updateDeviceOptions();
    navigator.mediaDevices.addEventListener('devicechange', this.updateDeviceOptions);

    const currentDevices = await this.getCurrentMediaDevices();
    this.selectedDevices = {
      videoInputId: currentDevices.videoInput?.deviceId,
      audioInputId: currentDevices.audioInput?.deviceId,
      audioOutputId: currentDevices.audioOutput?.deviceId || DEFAULT_AUDIO_OUTPUT_VALUE,
    };
  },

  beforeDestroy() {
    navigator.mediaDevices.removeEventListener('devicechange', this.updateDeviceOptions);
  },

  methods: {
    ...mapActions({
      updateAvailableMediaDevices: 'call/media/updateAvailableMediaDevices',
      getCurrentMediaDevices: 'call/media/getCurrentMediaDevices',
      toggleLocalVideo: 'call/media/toggleLocalVideo',
      togglePreviewVideo: 'call/media/togglePreviewVideo',
      turnCamera: 'call/media/turnCamera',
      doSaveMediaDevices: 'call/media/saveMediaDevices',
    }),
    async updateDeviceOptions() {
      await this.updateAvailableMediaDevices();

      const availableDevices = this.availableMediaDevices;
      this.deviceOptions = {
        videoInput: this.getDeviceOptions(availableDevices.videoInput),
        audioInput: this.getDeviceOptions(availableDevices.audioInput),
        audioOutput: availableDevices.audioOutput.length
          ? this.getDeviceOptions(availableDevices.audioOutput)
          : [ { optValue: DEFAULT_AUDIO_OUTPUT_VALUE, optLabel: this.$t('system_default') } ],
      };
    },
    getDeviceOptions(availableDevices) {
      if (availableDevices.length === 0) return undefined;
      return availableDevices.map(availableDevice => ({
        optValue: availableDevice.deviceId,
        optLabel: availableDevice.label,
      }));
    },
    async saveMediaDevices() {
      const availableDevices = this.availableMediaDevices;
      const currentDevices = await this.getCurrentMediaDevices();

      const wasAudioUpdate = this.selectedDevices.audioInputId !== currentDevices.audioInput.deviceId;
      const wasVideoUpdate = this.selectedDevices.videoInputId !== currentDevices.videoInput.deviceId;
      // In Firefox, when saving a new microphone/camera,
      // the permission to access the camera is removed and the video stops working.
      // So we reset the camera so that it continues to work.
      const needResetCamera = (wasAudioUpdate || wasVideoUpdate);

      if (needResetCamera) {
        await this.turnCamera({ on: false });
      }
      this.doSaveMediaDevices({
        videoInput: this.findDevice(availableDevices.videoInput, this.selectedDevices.videoInputId),
        audioInput: this.findDevice(availableDevices.audioInput, this.selectedDevices.audioInputId),
        audioOutput: this.findDevice(availableDevices.audioOutput, this.selectedDevices.audioOutputId),
      });
      if (needResetCamera && this.isVideoEnabled) {
        if (this.isPreviewVideo) {
          this.togglePreviewVideo(this.isVideoEnabled);
        } else {
          this.toggleLocalVideo(this.isVideoEnabled);
        }
      }

      this.closeModal();
    },
    findDevice(availableDevices, deviceId) {
      if (availableDevices.length === 0) return undefined;
      return availableDevices.find(availableDevice => availableDevice.deviceId === deviceId);
    },
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
$space-around: 48px;

.settings-modal {
  &::v-deep {
    .sword-modal-container {
      height: 100%;
      max-height: calc(100vh - #{2 * $space-around});
      width: 100%;
      max-width: calc(100vw - #{2 * $space-around});;
    }

    .modal-top-bar-icon {
      height: 16px;
      width: 16px;
      margin: map-get($sh-spacers-wuk, 1);
    }

    .sword-modal-header {
      margin: map-get($sh-spacers-wuk, 0);
      text-align: left;

      h5 {
        margin: map-get($sh-spacers-wuk, 0);
      }
    }

    .sword-modal-body {
      margin-top: map-get($sh-spacers-wuk, 3) !important;
      margin-bottom: map-get($sh-spacers-wuk, 3) !important;
      padding-right: map-get($sh-spacers-wuk, 3) !important;
      padding-left: map-get($sh-spacers-wuk, 3) !important;

      @media screen and (min-width: $tablet-size) {
        padding-right: map-get($sh-spacers-wuk, 5) !important;
        padding-left: map-get($sh-spacers-wuk, 5) !important;
      }
    }

    .sword-modal-footer {
      padding-top: map-get($sh-spacers-wuk, 3) !important;
      padding-bottom: map-get($sh-spacers-wuk, 4) !important;
    }
  }
}

.field-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: map-get($sh-spacers-wuk, 3);

  .icon {
    display: none;
    height: 30px;
    width: 30px;
    margin-top: map-get($sh-spacers-wuk, 2);
  }

  @media screen and (min-width: $tablet-size) {
    .icon {
      display: block;
    }
  }
}

.field-wrap {
  display: flex;
}

.field {
  width: calc(100vw - #{4 * $space-around});
  text-align: left;
  &::v-deep {
    .sword-input-label-container-wuk {
      .sword-input-label-wuk {
        @extend %t2;
        @extend %-t_bold;
      }
    }
  }

  @media screen and (min-width: $tablet-size) {
    width: 384px;
  }
  @media screen and (min-width: $desktop-size) {
    width: 472px;
  }
}
</style>
